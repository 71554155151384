/* App.css */
/* App.css */
.ant-layout-content {
  background-color: #ffffff; /* Change this to your desired background color */
  padding: 50px;
  background-image: url('../public/background.svg');
  background-size: 100% 160%; /* Increase the height to push the bottom of the image beyond the viewport */
  background-position: center -20%; /* Shift the background image up to show only the top 80% */
  background-repeat: no-repeat;
  background-attachment: fixed; /* This will fix the background to the viewport */
  min-height: 760px; /* Ensuring a minimum height for the content */
}



.ant-layout-header {
  position: relative;
  z-index: 0;
  background: #4f4b5a;
  height: 100px;
}

.logo {
  position: absolute;
  top: -180px; /* Adjust based on how much of the logo you want over the header */
  left: 20px; /* Adjust as needed */
  z-index: 1;
  height: 140px; /* Adjust height to fit half of it in header and half in content */
}

.left-side-content {
  text-align: left;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  z-index: 0;
}

.left-side-content img {
  margin-bottom: 20px;
  height: 200px; /* Adjust the logo size */
}

.main-title {
  color: #2c3e50;
  margin-bottom: 16px;
  text-align: left;
  font-size: 24px;
}

.description {
  font-size: 16px;
  color: #34495e;
  margin-bottom: 24px;
}

.features-list {
  list-style: none;
  padding: 0;
}

.features-list li {
  margin-bottom: 16px;
}

.features-list li strong {
  color: #e89507;
}

.features-list li p {
  margin: 0;
}

/* Menu styles */
.ant-menu-horizontal > .ant-menu-item a {
  color: white !important;
}

.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #e89507 !important; /* Optional: change color on hover */
}

.ant-menu-horizontal > .ant-menu-item {
  padding: 0 20px;
}

.ant-menu-horizontal > .ant-menu-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: transparent;
  transition: background 0.3s;
}

/* Change the color of the active menu item's underline */
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after {
  border-bottom-color: #e89507 !important;
}

/* Change the color of the active menu item's text */
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
  color: #e89507 !important;
}
